import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@com-banistmo/logic-lib-emp';

import { default as jwt_decode } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiGateway } from 'src/app/settings/models/enviroment-consts/api-gateway';
import FingerprintJS from '@com-banistmo/fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public token: string;
  public clientId: string;
  public username: string;
  public isMobile: boolean;
  public otp: string;
  public redirectURI: string;
  public state: string;
  public mfa_token: string;
  public nonce: string;
  public multiSessionToken: string;
  public captchaValue: string;
  public codeChallenge: string;
  public codeMethodChallenge: string;


  private readonly $keyPublic = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient, 
    private configService: ConfigService) {}

  public setSessionData(token: string, isMobile?: boolean, otp?: string) {
    this.setToken(token);
    this.isMobile = isMobile === true;
    this.otp = otp;
  }

  public setToken(token: string) {
    this.token = token;
    const decoded = jwt_decode(token);
    this.clientId = decoded.aud;
    this.username = decoded.sub;
  }

  public setKeyPublic(key: string): void {
    this.$keyPublic.next(key);
  }

  public getKeyPublic(): any {
    return this.$keyPublic.value;
  }

  public getChannelStatus(channel: string=null): Observable<any>  {
    const httpOptions = {
      headers: new HttpHeaders({'Authorization': `bearer ${​​​​this.configService.config.token.value}` })
    };
    return this.http.get(ApiGateway.CHANNEL_STATUS + channel +"/status",httpOptions);
  }

  async getFingerPrint(): Promise<string> {
    const fp = await FingerprintJS.load({ debug: true });
    let fpGet = await fp.get();
 
    return fpGet.visitorId;
  }
}