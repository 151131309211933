import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../core/services/data/data.service';
import { SecurityService } from '../../core/services/security/security.service';
import { SEMICOLON_FORMAT, QUERY_STRING_FORMAT } from './consts/gateway';
import { PasswordActions } from './consts/password-actions';
import { Title } from '@angular/platform-browser';
import { TranslateLanguageService } from '../../core/services/translate-language/translate-language.service';


@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnInit {

  newTitle: string;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly dataService: DataService,
    private readonly securityService: SecurityService,
    private titleService: Title,
    private translateLanguageService: TranslateLanguageService
  ) {
  }

  ngOnInit() {
    const url = this.router.url;
    this.setTitle();
    if (url && url.search(SEMICOLON_FORMAT) != -1) {
      const paramMap = this.route.snapshot.paramMap;
      this.evaluateParameters(paramMap);
    } else if (url && url.search(QUERY_STRING_FORMAT) != -1) {
      this.route.queryParamMap.subscribe(params => {
        this.evaluateParameters(params);
      });
    } else {
      this.router.navigate(['not-found']);
    }
  }

  public setTitle() {
    if (!this.newTitle || this.newTitle === '') {
      this.translateLanguageService.getTranslation('default.titlePageDefault').then((translated) => {
        this.titleService.setTitle(translated);
      });
    }
  }

  private evaluateParameters(paramMap) {
    this.handleLanguage(paramMap);
    const action = paramMap.get('action');
    this.executeAction(action, paramMap);
  }

  private executeAction(action, paramMap) {
    if (action) {
      switch (PasswordActions[action]) {
        case PasswordActions.password_change:
          this.handlePasswordChange(paramMap);
          break;
        case PasswordActions.password_forgot:
          this.handlePasswordForgot(paramMap);
          break;
        case PasswordActions.login:
          this.handleLogin(paramMap);
          break;
        default:
          this.router.navigate(['not-found']);
          break;
      }
    } else {
      this.router.navigate(['not-found']);
    }
  }

  private handlePasswordChange(paramMap: ParamMap): void {
    this.dataService.isMobile = paramMap.get('isMobile') === 'true';
    this.router.navigate(['password', PasswordActions.password_change]);
  }

  private handlePasswordForgot(paramMap: ParamMap): void {
    const token = paramMap.get('token');
    const otp = paramMap.get('otp');
    if (!token || !otp) {
      this.router.navigate(['not-found']);
      return;
    }
    const isMobile = paramMap.get('isMobile') === 'true';
    this.dataService.setSessionData(token, isMobile, otp);
    this.securityService.validateToken().subscribe(
      () => { this.router.navigate(['password', PasswordActions.password_forgot]); },
      () => { this.router.navigate(['password', PasswordActions.token_expired]); }
    );
  }

  private handleLogin(paramMap: ParamMap): void {
    this.dataService.clientId = paramMap.get('client_id');
    this.dataService.redirectURI = paramMap.get('redirect_uri');
    this.dataService.state = paramMap.get('state');
    this.dataService.nonce = paramMap.get('nonce') ? paramMap.get('nonce') : '';
    this.router.navigate(['password', PasswordActions.login]);
  }

  private handleLanguage(paramMap: ParamMap) {
    const lang = paramMap.get('lang');
    if (lang === 'es' || lang === 'en') {
      this.translate.use(lang);
    }
  }

}

